<template>
  <div ref="boxRef" class="three-d-info-box">
    <section class="content-wrapper" :style="{ height: contentHeight + 'px' }">
      <div
        ref="contentRef"
        class="content"
        :style="{ transform: `translateY(-${position}px)` }"
      >
        <div class="item">
          <p class="title">Project Site</p>
          <span class="content">{{ data.site }}</span>
        </div>
        <div class="item">
          <p class="title">Dimension</p>
          <span class="content">{{ data.dimension }}</span>
        </div>
        <div class="item">
          <p class="title">Description</p>
          <span class="content">{{ data.description }}</span>
        </div>
        <!-- <div class="item">
          <p class="title">Links</p>
          <ul class="links">
            <li :key="link.label" v-for="link in data.links">
              <a :href="link.link" class="content">{{ link.label }}</a>
            </li>
          </ul>
        </div> -->
      </div>
    </section>
    <div ref="bottomRef" class="bottom">
      <section class="indicators">
        <button @click="scrollUp" type="button" class="up">
          <img src="@/images/icons/uptriangle.svg" />
        </button>
        <button @click="scrollDown" type="button" class="down">
          <img src="@/images/icons/uptriangle.svg" />
        </button>
      </section>
    </div>
  </div>
</template>

<script>
import { computed, inject, ref } from "vue";

export default {
  name: "ThreeDInfoBox",
  props: {
    data: Object,
  },
  setup(props) {
    // const { site, dimension, description, links } = data;

    const media = inject("media");
    const position = ref(0);
    const boxRef = ref(null);
    const contentRef = ref(null);
    const bottomRef = ref(null);
    const contentHeight = computed(() => {
      if (!boxRef.value) return 250;
      if (media === "mobile") return 250;
      const margin = 30;
      return boxRef.value.offsetHeight - bottomRef.value.offsetHeight - margin;
    });

    const scrollUp = () => {
      if (position.value === 0) return;

      position.value -= 50;
    };

    const scrollDown = () => {
      const maxScrollHeight =
        contentRef.value.offsetHeight - contentHeight.value;
      if (position.value > maxScrollHeight) return;

      position.value += 50;
    };

    return {
      media,
      position,
      boxRef,
      contentRef,
      bottomRef,
      contentHeight,
      // site,
      // dimension,
      // description,
      // links,
      scrollUp,
      scrollDown,
    };
  },
};
</script>

<style lang="scss" scoped>
.three-d-info-box {
  display: flex;
  flex-direction: column;
  height: 100%;

  @include RWD($tablet) {
    flex-direction: column-reverse;
  }

  .content-wrapper {
    height: 200px;
    overflow: hidden;

    & > .content {
      display: flex;
      flex-direction: column;

      .item {
        .title {
          font-family: "Red Rose";
          font-size: 18px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 1px;
          margin-bottom: 15px;

          @include RWD($mediumLaptop) {
            font-size: 12px;
            margin-bottom: 5px;
          }

          @include RWD($tablet) {
            font-size: 14px;
            line-height: 18px;
            margin-bottom: 12px;
          }

          &::before {
            content: "";
            display: inline-block;
            width: 13px;
            height: 13px;
            background-color: $white;
            margin-right: 14px;
            position: relative;

            @include RWD($mediumLaptop) {
              width: 10px;
              height: 10px;
            }

            @include RWD($tablet) {
              width: 8px;
              height: 8px;
              margin-right: 10px;
            }
          }
        }

        .content {
          display: block;
          color: $white;
          margin-left: 27px;
          font-family: "Zpix";
          font-size: 18px;
          font-weight: 500;
          line-height: 30px;
          letter-spacing: 1px;

          @include RWD($mediumLaptop) {
            font-size: 12px;
            line-height: 24px;
          }

          @include RWD($tablet) {
            margin-left: 18px;
            font-size: 14px;
            line-height: 30px;
          }
        }

        ul.links {
          list-style: none;
          padding: 0;
        }

        &:not(:last-child) {
          margin-bottom: 35px;

          @include RWD($mediumLaptop) {
            margin-bottom: 30px;
          }

          @include RWD($tablet) {
            margin-bottom: 30px;
          }
        }
      }
    }
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;

    @include RWD($tablet) {
      margin-top: 0;
      margin-bottom: 12px;
    }

    .index {
      padding-left: 32px;
      font-size: 0;

      span {
        color: $white;
        font-family: "Zpix";
        font-size: 20px;
        letter-spacing: 0.04em;

        @include RWD($mediumLaptop) {
          font-size: 12px;
        }
      }
    }

    .indicators {
      font-size: 0;
      margin-left: auto;

      & > button {
        font-size: 0;

        img {
          width: 100%;
          filter: invert(1);
        }

        &.down {
          margin-left: 10px;
          img {
            transform: rotate(180deg);
          }
        }

        @include RWD($mediumLaptop) {
          width: 12px;
        }

        @include RWD($tablet) {
          opacity: 0.5;
          width: 10px;

          img {
            width: 100%;
            filter: invert(1);
          }

          &.down {
            margin-left: 5px;
          }
        }
      }
    }
  }
}
</style>
