<template>
  <teleport to="#portal-target">
    <div class="three-d-box-modal">
      <div class="content-box">
        <div class="left">
          <div class="title">
            <p>3D Work Title</p>
            <div ref="leftSecondRowRef" class="content">
              <span>{{ data.name_en }}</span>
              <span>{{ data.name }}</span>
            </div>
          </div>
          <div class="three-d">
            <template v-if="!toggleThreedModel && viewImage">
              <div class="plugin-area">
                <img :src="viewImage" />
              </div>
            </template>
            <template v-else>
              <SketchfabEmbed
                v-if="data.threeDWorkUrl"
                class="plugin-area"
                :url="data.threeDWorkUrl"
              />
              <div v-else class="plugin-area">
                <img :src="data.coverImage" />
              </div>
            </template>
            <div
              class="hint"
              @click="toggleThreedModel = !toggleThreedModel"
              v-if="data.threeDWorkUrl"
            >
              <img class="icon" src="@/images/icons/drag-icon.png" />
              <span>
                {{
                  $route.path.includes("portfolios")
                    ? "Drag to view the 3D work"
                    : "Toggle to view the 3D work"
                }}
              </span>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="category">
            <p>Category</p>
            <div class="content" :style="{ height: secondRowHeight }">
              <img :src="renderIcon" />
              <p>
                <span>{{ data.category }}</span>
                <span>{{ data.categoryNameEn }}</span>
              </p>
            </div>
          </div>
          <div class="detail-info">
            <ThreeDInfoBox :key="JSON.stringify(data)" :data="data" />
          </div>
          <div class="buttons">
            <button class="button" @click="clickHandler('portfolios')">
              BACK TO STUDENT
            </button>
            <button class="button" @click="clickHandler('3d-tour')">
              CONTINUE 3D TOUR
            </button>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import { ref, onMounted, onUnmounted, inject, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import ThreeDInfoBox from "@/components/atoms/ThreeDInfoBox";
import SketchfabEmbed from "@/components/atoms/SketchfabEmbed";

import whiteCate01 from "@/images/categories-icon/white/01.png";
import whiteCate02 from "@/images/categories-icon/white/02.png";
import whiteCate03 from "@/images/categories-icon/white/03.png";
import whiteCate04 from "@/images/categories-icon/white/04.png";
import whiteCate05 from "@/images/categories-icon/white/05.png";
import whiteCate06 from "@/images/categories-icon/white/06.png";
import whiteCate07 from "@/images/categories-icon/white/07.png";
import whiteCate08 from "@/images/categories-icon/white/08.png";
import whiteCate09 from "@/images/categories-icon/white/09.png";
import whiteCate10 from "@/images/categories-icon/white/10.png";
import whiteCate11 from "@/images/categories-icon/white/11.png";
import whiteCate12 from "@/images/categories-icon/white/12.png";
import whiteCate13 from "@/images/categories-icon/white/13.png";
import whiteCate14 from "@/images/categories-icon/white/14.png";
import whiteCate15 from "@/images/categories-icon/white/15.png";

export default {
  name: "ThreeDModal",
  components: { ThreeDInfoBox, SketchfabEmbed },
  props: {
    data: Object,
    viewImage: {
      type: String,
      default: "",
    },
  },
  emits: ["close"],
  setup(props, { emit }) {
    const { data } = props;
    const leftSecondRowRef = ref(null);
    const media = inject("media");
    const router = useRouter();
    const route = useRoute();
    const toggleThreedModel = ref(false);

    const renderIcon = computed(() => {
      const lookup = {
        1: whiteCate01,
        2: whiteCate02,
        3: whiteCate03,
        4: whiteCate04,
        5: whiteCate05,
        6: whiteCate06,
        7: whiteCate07,
        8: whiteCate08,
        9: whiteCate09,
        10: whiteCate10,
        11: whiteCate11,
        12: whiteCate12,
        13: whiteCate13,
        14: whiteCate14,
        15: whiteCate15,
      };
      const key = props.data.categoryIndex;
      return lookup[key];
    });

    const secondRowHeight = computed(() => {
      if (!leftSecondRowRef.value) return 0;
      if (media.value === "mobile") return "auto";
      return leftSecondRowRef.value.offsetHeight + "px";
    });

    onMounted(() => {
      app.style.filter = "blur(8px)";
      document.body.style.overflow = "hidden";
    });

    onUnmounted(() => {
      app.style.filter = "unset";
      document.body.style.overflow = "auto";
    });

    function clickHandler(destination) {
      emit("close");

      if (route.path.includes(destination)) return;
      if (destination.includes("portfolios")) {
        destination = destination.concat(`/${props.data.work_id}`);
      }

      router.push(`/${destination}`);
    }

    return {
      data,
      renderIcon,
      leftSecondRowRef,
      secondRowHeight,
      clickHandler,
      toggleThreedModel,
    };
  },
};
</script>

<style lang="scss" scoped>
$border-style: 1px solid $white;
$content-padding-x-desktop: 23px;
$content-padding-x-mobile: 10px;

@mixin title {
  font-family: "Red Rose";
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 1px;
  padding: 9px $content-padding-x-desktop;
  border-bottom: $border-style;
  margin: 0;

  @include RWD($mediumLaptop) {
    font-size: 14px;
    line-height: 19px;
  }

  @include RWD($tablet) {
    font-size: 12px;
    line-height: 15px;
    padding: 10px $content-padding-x-mobile;
  }
}

.three-d-box-modal {
  @include modal;
  min-height: 100vh;
  height: 100%;
  overflow: scroll;
  padding: 30px;

  @include RWD($tablet) {
    padding: 12px;
  }

  * {
    color: white;
  }

  & > .content-box {
    display: flex;
    flex: 0 0 100%;
    background-color: $black;
    outline: $border-style;
    margin: auto;
    max-width: 1620px;

    @include RWD($tablet) {
      display: block;
      width: 100%;
    }

    & > .left {
      flex: 0 0 73%;
      border-right: $border-style;

      @include RWD($tablet) {
        border-right: none;
      }

      & > .title {
        & > p {
          @include title;
        }

        & > .content {
          padding: 17px $content-padding-x-desktop;
          border-bottom: $border-style;

          @include RWD($tablet) {
            padding: 12px $content-padding-x-mobile;
          }

          & > span {
            display: block;

            &:first-child {
              font-family: "Red Rose";
              font-size: 40px;
              line-height: 47px;
              font-weight: 400;
              letter-spacing: 2px;
              white-space: pre-line;
              margin-bottom: 5px;

              @include RWD($mediumLaptop) {
                font-size: 30px;
                line-height: 32px;
              }

              @include RWD($tablet) {
                font-size: 16px;
                line-height: 20px;
                margin-bottom: 10px;
              }
            }

            &:last-child {
              font-family: "Zpix";
              font-size: 18px;
              font-weight: 500;
              line-height: 32px;
              letter-spacing: 1px;

              @include RWD($mediumLaptop) {
                font-size: 12px;
                line-height: 26px;
              }

              @include RWD($tablet) {
                font-size: 14px;
                line-height: 14px;
              }
            }
          }
        }
      }

      & > .three-d {
        padding: 53px $content-padding-x-desktop 14px;

        @include RWD($tablet) {
          padding: 15px $content-padding-x-mobile;
          border-bottom: $border-style;
        }

        & > .plugin-area {
          width: 100%;
          padding-bottom: calc((100% * 541) / 1092);
          background-color: black;
          margin-bottom: 65px;
          position: relative;

          & > img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
          }

          @include RWD($tablet) {
            margin-bottom: 15px;
          }
        }

        & > .hint {
          cursor: pointer;
          .icon {
            width: 20px;
            height: 20px;

            @include RWD($tablet) {
              width: 16px;
              height: 16px;
            }
          }

          span {
            font-family: "Red Rose";
            font-size: 16px;
            line-height: 20px;
            font-weight: 400;
            letter-spacing: 1px;
            margin-left: 12px;

            @include RWD($tablet) {
              font-size: 14px;
              line-height: 18px;
              margin-left: 10px;
            }
          }
        }
      }
    }

    & > .right {
      flex: 0 0 27%;
      display: flex;
      flex-direction: column;

      & > .category {
        & > p {
          @include title;
        }

        & > .content {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 20px;
          border-bottom: $border-style;

          @include RWD($tablet) {
            padding: 30px;
          }

          & > img {
            width: 70px;
            margin-right: 45px;

            @include RWD($mediumLaptop) {
              width: 65px;
              margin-right: 30px;
            }

            @include RWD($smallLaptop) {
              width: 50px;
              margin-right: 20px;
            }

            @include RWD($tablet) {
              width: 50px;
              margin-right: 30px;
            }
          }

          & > p {
            margin: 0;

            @include RWD($tablet) {
              max-width: unset;
            }

            & > span {
              display: block;
              font-family: "Zpix";
              font-size: 15px;
              line-height: 26px;
              font-weight: 500;
              letter-spacing: 1px;

              &:first-child {
                white-space: nowrap;
              }

              @include RWD($mediumLaptop) {
                font-size: 14px;
                line-height: 20px;
              }

              @include RWD($tablet) {
                font-size: 14px;
                line-height: 28px;
              }
            }
          }
        }
      }

      & > .detail-info {
        flex: 1 1 auto;
        padding: 40px;
        border-bottom: $border-style;

        @include RWD($mediumLaptop) {
          padding: 30px;
        }

        @include RWD($tablet) {
          padding: 20px 20px 25px;
        }
      }

      & > .buttons {
        .button {
          display: inline-block;
          width: 50%;
          font-family: "Zpix";
          font-size: 16px;
          font-weight: 500;
          line-height: 26px;
          letter-spacing: 1px;
          padding: 14px 0;
          text-align: center;
          text-decoration: none;

          @include RWD($mediumLaptop) {
            font-size: 12px;
            padding: 10px 0;
          }

          @include RWD($tablet) {
            width: 100%;
            padding: 42px 0;
          }

          &:first-child {
            border-right: $border-style;

            @include RWD($tablet) {
              border-bottom: $border-style;
              border-right: none;
            }
          }

          &:hover {
            background-color: $neon;
          }
        }
      }
    }
  }
}
</style>
