<template>
  <div class="sketchfab-embed">
    <section class="content-wrapper">
      <iframe
        frameborder="0"
        allowfullscreen
        mozallowfullscreen="true"
        webkitallowfullscreen="true"
        allow="autoplay; fullscreen; xr-spatial-tracking"
        width="100%"
        height="100%"
        xr-spatial-tracking
        execution-while-out-of-viewport
        execution-while-not-rendered
        web-share
        :src="url||'https://sketchfab.com/models/08f9730a5bba49119d9f45e7be4d1593/embed'"
      ></iframe>
    </section>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  name: "SketchfabEmbed",
  props: {
    url: String,
  },
  setup() {},
};
</script>

<style lang="scss" scoped>
.sketchfab-embed {
  position: relative;
  & > section.content-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    // & > iframe {
    //   width: 100%;
    //   height: 100%;
    // }
  }

}
</style>
